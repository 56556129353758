.main-body{
    padding: 5vh
}

#b_ul li{
    width: 90px;
    text-align: center;
    border: solid thin #891AFF;
    border-bottom: solid thick #891AFF;
    margin-right: 10px;
    color: black;
}

.initialisation-container{
    background-color: #81D4FA;
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
}

